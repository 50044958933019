<template>
  <div>
    <!-- Header-->
    <header class="game all-games">
      <div class="container px-4 mb-4 text-center">
        <h1 class="text-uppercase">Terms and Conditions</h1>
        <div class="horizontal-divider"></div>
      </div>
    </header>

    <!-- <section>
      <div class="container mb-3">
        <div class="row">
          <div class="col-lg-12">
            <div class="my-4">
              <h1 class="text-uppercase text-left">Terms and Conditions</h1>
              <div class="horizontal-divider m-0"></div>
            </div>
          </div>
        </div>
      </div>
    </section> -->
    <div class="mb-5">
      <div class="row d-flex justify-content-center">
        <div class="col-lg-10 col-offset-1" style="height: 100vh">
          <object
            :data="`${terms}#toolbar=0&navpanes=0&scrollbar=0`"
            width="100%"
            height="100%"
            class="py-3"
          ></object>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    terms() {
      return require("../assets/pdf/bd-tc.pdf");
    },
  },
};
</script>

<style>
html,
body {
  background-color: #ffffff !important;
}
</style>
